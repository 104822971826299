@import "../../style/variables.scss";

.modal {
    background: white;
    width: 60%;
    margin: auto auto;
    border-radius: 3px;
    box-shadow: 0 0 16px 32px rgba(0,0,0, 0.05);
    margin-top: 2rem;
    position: relative;
    padding: 1rem 2rem;
}

.buttonContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
}

.saveButton {
    width: 240px;
}

.closeButton {
    margin-left: 1rem;
    position: absolute;
    top: 2rem;
    right: 2rem;
}

.deleteButton {
    margin-left: 1rem;
}

.panoramaImageContainer {
    width: 100%;
    position: relative;
    cursor: pointer;
    > img {
        width: 100%;
        user-select: none;
    }
}

.panoramaImageMarker {
    width: 32px;
    height: 32px;
    background: $color-lightblue;
    border: 3px solid white;
    border-radius: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    pointer-events: none;
}

.panoramaOtherMarker {
    width: 24px;
    height: 24px;
    background: $color-mediumgrey;
    border: 2px solid $color-lightgrey;
    border-radius: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    opacity: 0.75;
    pointer-events: none;
}

.instructions {
    color: $color-primary;
}

.modalForm {
    width: 100%;
    
    input {
        width: 100%;
    }
}