@import "../../style/variables.scss";

.container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;

    button {
        margin-left: 1rem;
    }
}

.button {
    background: none;
    color: $color-primary;
    padding: 0;
    width: 24px;
    height: 24px;
    border-radius: 2px;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;

    transform: translateY(-1px);

    transition: background 0.2s, color 0.2s;
    &:hover {
        background: $color-primary;
        color: white;
    }

    > svg {
        width: 16px;
        height: 16px;
    }

    &[data-negative=true] {
        color: $color-red;
        &:hover {
            background: $color-red;
            color: white;
        }
    }
}
