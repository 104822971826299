.modalWrapper {
    position: fixed;
    z-index: 9001;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background 0.1s;

    &[data-visible=true] {
        opacity: 1.0;
        pointer-events: auto;
        background: rgba(0,0,0,0.5);
    }
    &[data-visible=false] {
        opacity: 0.0;
        pointer-events: none;
        background: rgba(0,0,0,0.0);
    }
}