@import "../../style/variables.scss";

.panoramaImageContainer {
    background: $color-lightgrey;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    min-height: 420px;
    max-width: 840px;
}

.panoramaImage {
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.panoramaPlaceholder {

}

.panoramaImageMarker {
    width: 24px;
    height: 24px;
    background: $color-lightblue;
    border: 3px solid white;
    border-radius: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    cursor: pointer;
    opacity: 0.75;
    transition: opacity 0.2s, transform 0.2s;

    &[data-hovered=true] {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1.3);
    }
}

.placeHeading {
    display: flex;
    align-items: center;

    > button {
        margin-left: 1rem;
    }
}

.placeTable {

    border-collapse: collapse;

    th {
        text-align: left;
    }

    p {
        width: 100%;
        text-align: left;
    }

    thead > tr th {
        padding-bottom: 0.5rem;
        border-bottom: 1px solid $color-lightgrey;
    }

    th:first-child {
        width: 640px;
        padding-left: 0.5rem;
    }

    tbody {
        font-weight: 300;
    }

    tbody td {
        height: 60px;
        border-bottom: 1px solid $color-lightgrey;
        transition: background 0.2s;
        cursor: pointer;
    }

    tbody tr[data-hovered=true] td {
        background: rgba($color-lightblue, 0.1);
    }

    tr td:first-child {
        padding-left: 0.5rem;
    }

    tbody tr:last-child td {
        border-bottom: none;
    }
}

.placeTableButtonCell {
    padding-right: 0.5rem;
    > button:first-child {
        margin-right: 0.5rem;
    }
}

.imageForm {
    position: relative;
    display: inline-flex;

    button {
        width: 202px;
    }
}

.imageFormFileField {
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
    pointer-events: none;
	overflow: hidden;
	z-index: -1;
}

.imageFormUploadButton {
    font-family: $font-stack;
    padding: 0.75rem 1.5rem;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-align: center;
    cursor: pointer;

    color: $color-primary;
    background: white;
    border: 1px solid $color-lightgrey;

    transition: background 0.2s, color 0.2s;

    &:hover {
        background: rgba($color-lightgrey, 0.5);
    }
}

.imageFormUploadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background: rgba(white, 0.8);
    color: $color-lightblue;
}