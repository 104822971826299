@import "./variables.scss";

.page {
    position: relative;
    padding: 2rem;
}

.backLink {
    position: absolute;
    top: 0;
    padding-left: 0.5rem;
    text-decoration: none;
    color: $color-blue;

    opacity: 0.8;

    transition: opacity 0.2s;
    &:hover {
        opacity: 1.0;
    }

    &:before {
        content: '<';
        position: relative;
        left: -0.5rem;
    }
}

.row {
    margin: 1rem 0;
    width: 100%;
}