@import "../../style/variables.scss";

.downloadButton,
.toogleCommentButton {
    display: block;
    width: 330px;
}

.componentList {
    padding-left: 0;
    margin: 2rem 0;
}

.componentCard {
    display: flex;
    flex-direction: column;
    padding: 0;
    background: white;
    box-shadow: 0 4px 16px 4px rgba(0, 0, 0, 0.05);
    margin: 1rem 0;
    max-width: 600px;
    border-radius: 5px;
    overflow: hidden;
}

.componentHeader {
    background: rgba($color-primary, 0.1);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;

    h3 {
        margin: 0;
    }
}

.componentContent {
    padding: 1rem;

    > h4 {
        margin: 0;
        font-weight: normal;
    }

    > button {
        margin-top: 0.5rem;
        max-width: 200px;
    }
}

.newPanoForm,
.newComponentForm {
    width: 420px;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    > input {
        flex-grow: 1;
        margin-right: 1rem;
    }
}

.newComponentForm {
    margin-left: 1rem;
}

.panoLink {
    padding: 0.25rem 0;
    display: block;
    text-decoration: none;

    &:hover {
        > span {
            text-decoration: underline;
        }
    }
}

.componentRank {
    width: 24px;
    height: 24px;
    background: $color-lightblue;
    border: 3px solid white;
    border-radius: 50%;
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 0.75rem;
    margin-right: 0.5rem;
}

.deleteComponentButton {
    background: none;
    color: $color-red;
    padding: 0 0.25rem;
    height: 24px;
    border-radius: 2px;
    box-sizing: border-box;
    text-transform: uppercase;
    font-size: 0.75rem;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: background 0.2s, color 0.2s;
    &:hover {
        background: $color-red;
        color: white;
    }

    > span {
        transform: translateY(1px);
    }

    > svg {
        width: 16px;
        height: 16px;
        margin-left: 0.25rem;
    }
}

.imageContainer {
    margin-bottom: 2rem;
    max-width: 600px;
    position: relative;
    > img {
        width: 100%;
        user-select: none;
    }
}

.imageInstructions {
    margin-top: 2rem;
}

.imageForm {
    position: relative;
    display: flex;

    button {
        width: 202px;
    }
}

.imageFormFileField {
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    z-index: -1;
}

.imageFormUploadButton {
    font-family: $font-stack;
    padding: 0.75rem 1.5rem;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-align: center;
    cursor: pointer;

    color: $color-primary;
    background: white;
    border: 1px solid $color-lightgrey;

    transition: background 0.2s, color 0.2s;

    &:hover {
        background: rgba($color-lightgrey, 0.5);
    }
}

.imageFormUploadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background: rgba(white, 0.8);
    color: $color-lightblue;
}

.stationMapMarker {
    width: 32px;
    height: 32px;
    background: $color-lightblue;
    border: 3px solid white;
    border-radius: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    transition: transform 0.2s;
    &[data-uninitialized="true"] {
        background: $color-red;
    }
    &[data-hovered="true"] {
        transform: translate(-50%, -50%) scale(1.06);
    }
    &[data-dragging="true"] {
        transform: translate(-50%, -50%) scale(1.12);
        pointer-events: none;
    }
}
