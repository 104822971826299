@import "../../style/variables.scss";

.tabItem {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    margin: 0;
    padding: 0;
    position: relative;
    padding: 1rem;
}
.button {
    background: none;
    font-size: 1.5rem;
    border-color: transparent;
    border-width: 4px 0;
    cursor: pointer;
    height: 100%;
    outline: 0;
    text-align: center;
    width: 100%;
    color: black;
    &:hover {
        color: $color-blue;
        text-decoration: underline;
    }
    &::after {
        content: "";
        background-color: $color-blue;
        height: 4px;
        width: 100%;
        position: absolute;
        top: 0;
        right: 0;
        transform: scale(0, 1);
        transition: transform 0.1s;
    }
    &[data-active="true"] {
        color: $color-blue;
        background-color: white;
        font-weight: bold;
        cursor: default;
        &::after {
            transform: scale(1, 1);
            transition-duration: 0.25s;
        }
        &:hover {
            opacity: 1;
            color: $color-blue;
            text-decoration: none;
        }
    }
}
