@import "../../style/variables.scss";

.btnWrapper {
    display: flex;
    text-align: right;
    padding: 0 0.5rem;
    > button {
        display: inline-block;
        width: 80px;
    }
}

.table {
    font-size: 0.75rem;

    :global {
        .rt-td {
            white-space: normal !important;
            vertical-align: middle;
            &:not(:first-child) {
                text-align: right;
            }
        }
    }
}