@import "./variables.scss";

body {
    font-family: $font-stack;
    margin: 0;
    * {
        box-sizing: border-box;
    }

    min-height: calc(100vh + 1px);
}

ul {
    list-style-type: none;
    padding-left: 1rem;
}

li {
    margin: 0.25rem;
}

button,
.button {
    font-family: $font-stack;
    padding: 0.75rem 1.5rem;
    background: $color-primary;
    border: none;
    border-radius: 3px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;

    transition: opacity 0.2s;
    &:hover {
        opacity: 0.8;
    }

    &:disabled {
        color: $color-text;
        background: $color-lightgrey;
        &:hover {
            opacity: 1;
        }
    }
}

button.light {
    color: $color-primary;
    background: white;
    border: 1px solid $color-lightgrey;

    transition: background 0.2s, color 0.2s;

    &:hover {
        background: rgba($color-lightgrey, 0.5);
    }
}

button.danger {
    color: white;
    background: $color-red;

    opacity: 0.8;

    transition: opacity 0.2s;

    &:hover {
        opacity: 1;
    }
}

button.small {
    padding: 0.25rem 0.5rem;
    font-size: 14px;
    line-height: 18px;
}

input {
    padding: 1rem;
    font-size: 1rem;
    font-family: $font-stack;
    border: 1px solid $color-lightgrey;
    border-radius: 3px;
}

input.small {
    padding: 0.25rem 0.5rem;
    font-size: 14px;
    line-height: 18px;
}

input[type="file"] {
    border: none;
}
